<script>
import { ODropdownItem } from '@oruga-ui/oruga-next/dist/esm/dropdown'

export default {
	name: 'SingaDropdownItem',
	extends: ODropdownItem
}
</script>

<style lang="sass" scoped>
:deep(.link-wrapper), :deep(.link)
	width: 100%
</style>

<style lang="sass">
.dropdown-item
	padding: 12px 24px
	@include fontSize(s)
	background-color: $color-grey-80
	color: $color-grey-30
	cursor: pointer
	&:hover
		color: white
		background-color: $transparent-white-8
	&.is-active
		background-color: $transparent-white-8
		@include font(basier, medium)
	&.nav-dropdown-button
		background-color: $color-grey-80
.dropdown-menu
	padding: $spacing-8 0 $spacing-8 0

.nav-user-dropdown
	.dropdown-item, .dropdown-item.is-active, .dropdown-item .link-wrapper
		border-radius: 0 !important
		@include fontSize(s)
		padding: 0
		> .dropdown-item-clickable, > a
			padding: 12px 24px
	.dropdown-item, .dropdown-item.is-active
		&:hover:not(.nav-dropdown-button)
				background: $transparent-white-8
				a, button
					color: white
		&.is-active:not(:hover)
			background-color: transparent
.filter-search, .nav-user-dropdown
	.dropdown-item, .dropdown-item.is-active
			&:hover:not(.nav-dropdown-button)
					background: $transparent-white-8
					a, button
						color: white
			&.is-active:not(:hover)
				background-color: transparent
</style>
