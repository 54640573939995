<script>
import { ODropdown } from '@oruga-ui/oruga-next/dist/esm/dropdown'

export default {
	name: 'SingaDropdown',
	extends: ODropdown
}
</script>

<style lang="sass" scoped>
.mobile-nav-menu
	.dropdown-menu
		min-width: 271px
		margin-top: $spacing-8
		@media (min-width: 350px)
			min-width: 300px
.dropdown-menu
	margin-top: $spacing-8
	overflow: hidden
.nav-user-dropdown
	.dropdown-menu
		width: 280px
</style>
